import React from 'react';

export default (
  <ol>
    <li>
      <h2>PROPÓSITO</h2>
      <p>
        Esta Política de privacidad es para residentes de California e informa acerca de las
        prácticas de privacidad de Spring Oaks Capital, LLC y sus filiales, incluida Spring Oaks
        Capital, SPV, LLC (en conjunto, (“Spring Oaks”, “nosotros” o “nos”). Esta política
        complementa la información contenida en la Política de privacidad de Spring Oaks, que se
        puede encontrar en <a href="https://springoakscapital.com/">springoakscapital.com</a>.
        Adoptamos esta política para cumplir con la Ley de Privacidad del Consumidor de California,
        modificada por la Ley de Derechos de Privacidad de California (California Consumer Privacy
        Act, “CCPA”). Cualquier término definido en la CCPA tiene el mismo significado que se
        utiliza en esta política.
      </p>
    </li>
    <li>
      <h2>ALCANCE</h2>
      <p>
        Esta Política cubre las prácticas de Spring Oaks con respecto a los residentes de California
        y se aplica a toda la información de identificación personal no pública, incluida la
        información contenida en los informes del consumidor, de nuestros clientes actuales y
        anteriores y de los consumidores con los que interactúa Spring Oaks. El sitio web de Spring
        Oaks no está destinado a personas menores de dieciocho años y no recopilamos conscientemente
        datos relacionados con personas menores de dieciocho años.
      </p>
      <p>
        Nada de lo contenido en este Aviso de privacidad pretende contradecir sus derechos en virtud
        de la Ley de Prácticas Justas de Cobro de Deudas (Fair Debt Collection Practices Act,
        FDCPA). Spring Oaks no divulgará ninguna información a terceros que esté prohibida por la
        FDCPA.
      </p>
    </li>
    <li>
      <h2>POLÍTICA</h2>
      <ol>
        <li>
          <b>Información que recopilamos sobre usted</b>
          <p>
            Podemos recopilar y utilizar la siguiente información personal que identifique, se
            relacione con, describa, haga referencia a, sea capaz de asociarse o pueda vincularse
            razonablemente con, directa o indirectamente, un consumidor, dispositivo u hogar
            (“información personal”). Hemos recopilado las siguientes categorías de información
            personal de los consumidores en los últimos 12 meses:
          </p>
          <table>
            <thead>
              <tr>
                <th>
                  <b>Categorías de información personal</b>
                </th>
                <th>
                  <b>Ejemplos de tipos específicos de información personal recopilada</b>
                </th>
                <th>
                  <b>Recopilada</b>
                </th>
                <th>
                  <b>PROPÓSITO</b>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>A. Identificadores</td>
                <td>
                  Un nombre real, alias, dirección postal, dirección de correo electrónico, números
                  de teléfono, dirección de protocolo de Internet, número de cuenta, número de
                  Seguro Social, fecha de nacimiento u otros identificadores similares.
                </td>
                <td>Sí</td>
                <td>
                  <p>(a) Necesario para prestar los servicios</p>
                  <p>(b) Necesario para cumplir con una obligación legal</p>
                  <p>(c) Necesario para nuestros intereses comerciales legítimos</p>
                  <p>(d) Evitar el fraude</p>
                </td>
              </tr>
              <tr>
                <td>
                  B. Categorías de información personal incluidas en la Ley de Registros de Clientes
                  de California (Código Civil de California §1798.80(e))
                </td>
                <td>
                  Nombre, firma, número de Seguro Social, características físicas o descripción,
                  dirección, número de teléfono, número de pasaporte, número de tarjeta de
                  identificación estatal o de licencia de conducir, número de póliza de seguro,
                  educación, empleo, historial laboral, número de cuenta bancaria, número de tarjeta
                  de crédito, número de tarjeta de débito o cualquier otra información financiera,
                  médica o de seguro médico. Parte de la información personal incluida en esta
                  categoría puede superponerse con otras categorías.
                </td>
                <td>Sí</td>
                <td>
                  <p>(a) Necesario para prestar los servicios</p>
                  <p>(b) Necesario para cumplir con una obligación legal</p>
                  <p>(c) Necesario para nuestros intereses comerciales legítimos</p>
                  <p>(d) Evitar el fraude</p>
                </td>
              </tr>
              <tr>
                <td>
                  C. Características de clasificación protegidas en virtud de las leyes federales o
                  de California
                </td>
                <td>
                  Edad (40 años o más), raza, color, ascendencia, nacionalidad, ciudadanía, religión
                  o credo, estado civil, afección médica, discapacidad física o mental, sexo
                  (incluido género, identidad de género, expresión de género, embarazo o parto y
                  afecciones médicas relacionadas), orientación sexual, condición de veterano o
                  militar, información genética (incluida información genética familiar).
                </td>
                <td>Sí</td>
                <td>
                  <p>(a) Necesario para prestar los servicios</p>
                  <p>(b) Necesario para cumplir con una obligación legal</p>
                  <p>(c) Necesario para nuestros intereses comerciales legítimos</p>
                </td>
              </tr>
              <tr>
                <td>D. Información comercial</td>
                <td>
                  Registros de bienes personales, productos o servicios adquiridos, obtenidos o
                  considerados, u otros historiales o tendencias de compra o consumo.
                </td>
                <td>Sí</td>
                <td>
                  <p>(a) Necesario para prestar los servicios</p>
                  <p>(b) Necesario para cumplir con una obligación legal</p>
                  <p>(c) Necesario para nuestros intereses comerciales legítimos</p>
                </td>
              </tr>
              <tr>
                <td>E. Datos sensoriales</td>
                <td>Información de audio, electrónica, visual, térmica, olfativa o similar.</td>
                <td>Sí</td>
                <td>
                  <p>(a) Necesario para prestar los servicios</p>
                  <p>(b) Necesario para cumplir con una obligación legal</p>
                  <p>(c) Necesario para nuestros intereses comerciales legítimos</p>
                </td>
              </tr>
              <tr>
                <td>F. Información profesional o relacionada con el empleo</td>
                <td>Historial laboral actual o pasado.</td>
                <td>Sí</td>
                <td>
                  <p>(a) Necesario para prestar los servicios</p>
                  <p>(b) Necesario para cumplir con una obligación legal</p>
                  <p>(c) Necesario para nuestros intereses comerciales legítimos</p>
                </td>
              </tr>
              <tr>
                <td>G. Información personal confidencial</td>
                <td>
                  Números de identificación emitidos por el gobierno, como licencia de conducir,
                  pasaporte o número de la seguridad social, detalles de cuentas financieras que
                  permiten el acceso a una cuenta, como un número de tarjeta de crédito y código de
                  acceso, datos genéticos, geolocalización precisa, raza o etnia, creencias
                  religiosas o filosóficas, afiliación sindical, contenido de correo electrónico o
                  mensajes de texto, datos biométricos relacionados con su identificación única.
                </td>
                <td>
                  No se recopila ni procesa información personal confidencial con el fin de deducir
                  características sobre un consumidor. Spring Oaks Capital no recopila datos
                  genéticos, geolocalización precisa, raza u origen étnico, creencias religiosas o
                  filosóficas, afiliación sindical, contenido de correo, correo electrónico o
                  mensajes de texto, datos biométricos relacionados con su identificación única
                </td>
                <td>
                  <p>(a) Necesario para prestar los servicios</p>
                  <p>(b) Mantener y dar servicio a su cuenta</p>
                  <p>(c) Procesar pagos</p>
                  <p>
                    (d) Garantizar la seguridad e integridad en relación con el uso de dicha
                    información personal
                  </p>
                  <p>(e) Verificar o mantener la seguridad y calidad de los servicios</p>
                  <p>(f) Necesario para cumplir con una obligación legal</p>
                  <p>(g) Necesario para nuestros intereses comerciales legítimos</p>
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            <b>
              La información personal <u>no</u> incluye:
            </b>
            <br />
            <ul>
              <li>Información disponible públicamente de registros gubernamentales.</li>
              <li>Información de consumidores anonimizada o agregada.</li>
              <li>
                Información excluida del alcance de la CCPA, como:
                <ul>
                  <li>
                    información médica o de salud cubierta por la Ley de Portabilidad y
                    Responsabilidad del Seguro Médico de 1996 (Health Insurance Portability and
                    Accountability Act, HIPAA) y la Ley de Confidencialidad de la Información Médica
                    de California (California Confidentiality of Medical Information Act, CMIA) o
                    los datos de ensayos clínicos;
                  </li>
                  <li>
                    información personal cubierta por ciertas leyes de privacidad específicas del
                    sector, incluida la Ley de Equidad de Informes de Crédito (Fair Credit Reporting
                    Act, FCRA), la Ley Gramm-Leach-Bliley (GLBA) o la Ley de Privacidad de la
                    Información Financiera de California (Financial Information Privacy Act, FIPA) y
                    la Ley de Protección de la Privacidad del Conductor de 1994.
                  </li>
                </ul>
              </li>
            </ul>
          </p>
        </li>
        <li>
          <b>Cómo se recopila su información personal</b>
          <p>
            Recopilamos la mayor parte de esta información personal directamente de vendedores de
            carteras de deuda, así como de usted por teléfono, correspondencia escrita por correo
            postal, correo electrónico, SMS, chat o fax, viendo sus páginas públicas de redes
            sociales u otra información disponible en línea. Sin embargo, también podemos recopilar
            información:
          </p>
          <ul>
            <li>
              de fuentes de acceso público (p. ej., registros de propiedades o registros
              judiciales);
            </li>
            <li>
              de nuestros proveedores de servicios (p. ej., proveedores de servicios, proveedor de
              cartas, proveedores de localización de deudores, proveedores de procesamiento de
              pagos, proveedores de análisis de llamadas y/o proveedores de servicios de firma
              electrónica);
            </li>
            <li>
              directamente de un tercero (p. ej., terceros contactados durante actividades de
              localización de deudores de conformidad con el Título 16 del Código de Estados Unidos
              (United States Code, U.S.C.) §1692b, como sus amigos, vecinos, familiares y/o
              empleador);
            </li>
            <li>
              de un tercero con su consentimiento (p. ej., su representante autorizado y/o abogado),
              y
            </li>
            <li>de la actividad en nuestro sitio web.</li>
          </ul>
        </li>
        <li>
          <b>Cómo utilizamos su información personal</b>
          <p>
            La información personal se recopila únicamente con el fin de recuperar deudas de una
            manera legal y sigue siendo parte de nuestros registros hasta que determinemos que la
            información ya no es necesaria, o estamos obligados por ley a eliminar dicha
            información. Recopilaremos la cantidad mínima de datos necesaria para cobrar una deuda.
          </p>
          <p>
            No recopilaremos categorías adicionales de información personal ni utilizaremos la
            información personal que hayamos recopilado para fines sustancialmente diferentes, no
            relacionados o incompatibles sin notificarle. No vendemos su información personal.
          </p>
          <p>
            También podemos utilizar o divulgar su información personal para uno o más de los
            siguientes fines comerciales:
          </p>
          <ul>
            <li>
              Para cumplir o satisfacer el motivo por el que proporcionó la información. Por
              ejemplo, si comparte su información personal para realizar un pago, utilizaremos esa
              información para procesar su pago.
            </li>
            <li>
              Para ayudar en el cobro de su cuenta que compramos o que estamos administrando para
              otra parte.
            </li>
            <li>Para proporcionarle la información que nos solicite.</li>
            <li>
              Detectar incidentes de seguridad, proteger contra actividades maliciosas, engañosas,
              fraudulentas o ilegales, y enjuiciar a los responsables de estas actividades.
            </li>
            <li>
              Eliminar fallos para identificar y reparar errores que afecten la funcionalidad
              prevista existente.
            </li>
            <li>
              Para responder a solicitudes de agentes policiales y según lo requiera la ley
              aplicable, una orden judicial o las reglamentaciones gubernamentales.
            </li>
            <li>
              Según sea necesario o apropiado para proteger los derechos, la propiedad o la
              seguridad de nosotros, nuestros clientes u otros.
            </li>
            <li>
              Tal y como se le describe al recopilar su información personal o como se establece en
              la CCPA.
              <br />
              <br />
            </li>
          </ul>
          <p>
            No recopilaremos categorías adicionales de información personal ni utilizaremos la
            información personal que hayamos recopilado para fines sustancialmente diferentes, no
            relacionados o incompatibles sin notificarle.
          </p>
        </li>
        <li>
          <b>Con quién compartimos su información personal</b>
          <p>
            Podemos compartir la información de identificación personal que recopilamos con nuestros
            empleados y filiales que necesitan conocer esa información para administrar su cuenta.
            Excepto por lo dispuesto a continuación, no compartimos ni divulgamos ninguna
            información de identificación personal a ninguna empresa o grupo de marketing externo a
            nosotros. Podemos compartir su información personal con terceros y proveedores de
            servicios en la medida en que sea razonablemente necesario para gestionar o administrar
            su cuenta, verificar el empleo, determinar la ubicación, procesar el pago, realizar una
            transacción, proporcionar servicio al cliente o según lo autorice la ley.
          </p>
          <p>
            Además, podemos divulgar información de identificación personal (i) a otra entidad con
            la que iniciemos o razonablemente podamos iniciar una transacción corporativa, como, por
            ejemplo, una fusión, consolidación, adquisición, o compra de activos, (ii) a un tercero
            de conformidad con una citación, orden judicial u otra forma de proceso legal, o en
            respuesta a una solicitud por, o en nombre de, cualquier agencia, departamento u
            organismo gubernamental, estatal, federal u otro, ya sea de conformidad o no con una
            citación, orden judicial u otra forma de proceso legal, o en relación con litigios
            iniciados contra, o en nombre de, Spring Oaks, cuando sea apropiado, (iii) a un tercero
            si Spring Oaks determina a su exclusivo criterio que dicha divulgación es apropiada para
            proteger la vida, salud o propiedad de Spring Oaks o cualquier otra persona o entidad,
            en cumplimiento de la legislación aplicable, (iv) a terceros según lo autorizado o
            designado por usted, o (v) para llevar a cabo cualquier otra actividad comercial
            legítima que no esté prohibida por ley. Lo anterior no pretende obviar ni sustituir
            ninguna obligación o responsabilidad legal aplicable a Spring Oaks.
          </p>
          <p>
            Excepto cuando sea necesario para que proporcionemos los servicios, la información o los
            productos solicitados por un usuario del sitio web, o excepto para las divulgaciones
            identificadas en los párrafos anteriores, el usuario puede excluirse de que su
            información de identificación personal, que nos ha sido proporcionada voluntariamente a
            través o desde su sitio web, sea conservada prospectivamente por nosotros, utilizada por
            nosotros para fines secundarios o divulgada por nosotros a terceros.
          </p>
          <p>
            Es posible que el correo electrónico publicado o enviado no sea seguro contra la
            interceptación por parte de personas no autorizadas. Para protegernos contra la
            interceptación por parte de personas no autorizadas, o debido a que no podemos verificar
            su identidad, es posible que no podamos responder a solicitudes de correo electrónico
            relacionadas con cuentas asignadas al cobro, a menos que usted nos haya solicitado o
            autorizado hacerlo.
          </p>
          <p>Compartir su información con agencias de informes de crédito del consumidor</p>
          <p>
            Las agencias de informes de crédito del consumidor (Consumer Reporting Agencies, CRA)
            recopilan y mantienen información sobre los perfiles de crédito de consumidores y
            empresas en nombre de organizaciones en los Estados Unidos. Podemos compartir
            información sobre usted con las CRA y realizar búsquedas periódicas con ellas para
            verificar su identidad o gestionar su cuenta.
          </p>
          <p>
            Los detalles de su(s) cuenta(s) con nosotros pueden enviarse a las CRA y ser registrados
            por ellas. Esta información puede ser suministrada por las CRA y puede ser utilizada y
            buscada por nosotros y otras organizaciones, como agencias de cobro de deudas, con el
            fin de:
          </p>
          <ul>
            <li>considerar solicitudes de crédito y servicios relacionados con el crédito;</li>
            <li>localizar deudores y recuperar deudas; y</li>
            <li>gestionar sus cuentas.</li>
          </ul>
          <p>
            Spring Oaks puede proporcionar información de cuenta a Experian, Equifax y TransUnion.
            Tiene derecho a obtener una copia anual de su informe crediticio de las CRA visitando{' '}
            <a href="https://www.annualcreditreport.com/index.action">www.annualcreditreport.com</a>
            .
          </p>
        </li>
        <li>
          <b>Sus derechos</b>
          <p>
            Tiene derecho, en virtud de la CCPA y otras leyes de privacidad y protección de datos,
            según corresponda, a ejercer de forma gratuita:
          </p>
          <ol>
            <li>
              <b>Divulgación de información personal que recopilamos sobre usted</b>
              <p>
                Tiene derecho a solicitar que le divulguemos cierta información sobre nuestra
                recopilación y uso de su información personal en los últimos 12 meses. Una vez que
                recibamos y confirmemos su solicitud de consumidor verificable, le divulgaremos:
              </p>
              <ul>
                <li>Las categorías de información personal que hemos recopilado sobre usted.</li>
                <li>Las categorías de fuentes de las que se recopila la información personal.</li>
                <li>
                  Nuestro fin comercial o empresarial para recopilar o vender información personal.
                </li>
                <li>
                  Las categorías de terceros con los que compartimos información personal, si
                  corresponde.
                </li>
                <li>Los datos personales específicos que hemos recopilado sobre usted.</li>
              </ul>
              <p>Tenga en cuenta que no estamos obligados a:</p>
              <ul>
                <li>
                  conservar cualquier información personal sobre usted que se haya recopilado para
                  una única transacción si, en el curso ordinario de los negocios, dicha información
                  sobre usted no se conserva;
                </li>
                <li>
                  reidentificar o vincular de otro modo cualquier dato que, en el curso ordinario de
                  los negocios, no se mantenga de una manera que se consideraría información
                  personal, o
                </li>
                <li>
                  proporcionarle la información personal más de dos veces en un período de 12 meses.
                </li>
              </ul>
            </li>
            <li>
              <b>Divulgación de información personal vendida o utilizada para fines comerciales</b>
              <p>
                En relación con cualquier información personal, que podemos vender o divulgar a un
                tercero para un fin comercial, usted tiene derecho a saber:
              </p>
              <ul>
                <li>
                  las categorías de información personal sobre usted que vendimos y las categorías
                  de terceros a los que se vendió la información personal, y
                </li>
                <li>
                  las categorías de información personal que divulgamos sobre usted para un fin
                  comercial.
                </li>
              </ul>
            </li>
            <li>
              <b>Derechos de solicitud de eliminación</b>
              <p>
                Tiene derecho a solicitar que eliminemos cualquier información personal suya que
                hayamos recopilado y conservado, con ciertas excepciones. Una vez que recibamos y
                confirmemos su solicitud de consumidor verificable, eliminaremos (y le pediremos a
                nuestros proveedores de servicios que eliminen) su información personal de nuestros
                registros, a menos que se aplique una excepción.
              </p>
              <p>
                Podemos denegar su solicitud de eliminación si es necesario conservar la información
                para que nosotros o nuestros proveedores de servicios hagamos lo siguiente:
              </p>
              <ul>
                <li>
                  completar la transacción para la que se recopiló la información personal,
                  proporcionar un bien o servicio solicitado por usted, o razonablemente anticipado
                  dentro del contexto de nuestra relación comercial continua con usted, o de otro
                  modo ejecutar un contrato entre usted y nosotros.
                </li>
                <li>
                  Detectar incidentes de seguridad, proteger contra actividades maliciosas,
                  engañosas, fraudulentas o ilegales, o enjuiciar a aquellos responsables de estas
                  actividades.
                </li>
                <li>
                  Eliminar fallos para identificar y reparar errores que afecten la funcionalidad
                  prevista existente.
                </li>
                <li>
                  Ejercer la libertad de expresión, garantizar el derecho de otro consumidor a
                  ejercer su derecho de libertad de expresión o ejercer otro derecho dispuesto por
                  la ley.
                </li>
                <li>
                  Cumplir con la Ley de Privacidad de Comunicaciones Electrónicas de California.
                </li>
                <li>
                  Participar en investigaciones científicas, históricas o estadísticas públicas o
                  revisadas por expertos en interés público que se adhieran a todas las demás leyes
                  de ética y privacidad aplicables, cuando sea probable que nuestra eliminación de
                  la información imposibilite o afecte gravemente la realización de dicha
                  investigación, siempre que hayamos obtenido su consentimiento informado.
                </li>
                <li>
                  Permitir únicamente usos internos que estén razonablemente alineados con sus
                  expectativas en función de su relación con nosotros.
                </li>
                <li>Cumplir con una obligación legal existente.</li>
                <li>
                  Utilizar su información personal, internamente, de una manera legal que sea
                  compatible con el contexto en el que proporcionó la información.
                </li>
              </ul>
            </li>
            <li>
              <b>Derecho a solicitar la corrección de datos personales</b>
              <p>
                Esto le permite corregir cualquier dato incompleto o inexacto que tengamos acerca de
                usted, aunque es posible que tengamos que verificar la exactitud de los nuevos datos
                que nos proporcione.
              </p>
            </li>
            <li>
              <b>
                Derecho a solicitar la restricción del tratamiento de datos personales sensibles
              </b>
              <p>
                Esto le permite solicitarnos que suspendamos el tratamiento de sus datos personales
                sensibles en los siguientes casos: (a) si desea que establezcamos la exactitud de
                los datos; (b) cuando nuestro uso de los datos sea ilegal, pero usted no desee que
                los borremos; (c) cuando necesite que conservemos los datos, aunque ya no los
                necesitemos, ya que los necesita para establecer, ejercer o defender demandas
                legales, o (d) usted se ha opuesto a nuestro uso de sus datos, pero necesitamos
                verificar si tenemos motivos legítimos predominantes para usarlos. Spring Oaks no
                recopila ni utiliza datos personales sensibles con el fin de inferir características
                acerca de los consumidores. Por lo tanto, dichos datos se tratan como “información
                personal”, y las solicitudes se gestionan de acuerdo con los procedimientos de
                información personal general.
              </p>
            </li>
            <li>
              <b>Protección contra la discriminación</b>
              <p>
                Tiene derecho a no ser discriminado por nosotros porque haya ejercido alguno de sus
                derechos en virtud de la CCPA. Esto significa que no podemos, entre otras cosas:
              </p>
              <ul>
                <li>denegarle bienes o servicios;</li>
                <li>
                  cobrar diferentes precios o tarifas por bienes o servicios, incluso mediante el
                  uso de descuentos u otros beneficios o la imposición de sanciones;
                </li>
                <li>proporcionarle un nivel o calidad diferente de bienes o servicios, o</li>
                <li>
                  sugerir que recibirá un precio o tarifa diferente por bienes o servicios o un
                  nivel o calidad diferente de bienes o servicios.
                </li>
              </ul>
              <p>
                Tenga en cuenta que podemos cobrarle un precio o tarifa diferente, o proporcionarle
                un nivel o calidad diferente de bienes y/o servicios, si esa diferencia está
                razonablemente relacionada con el valor que le proporciona su información personal.
              </p>
            </li>
            <li>
              <b>Agente autorizado</b>
              <p>
                Puede designar a un agente autorizado para que realice cualquiera de estas
                solicitudes proporcionando su autorización expresa por escrito. Debemos poder
                verificar su identidad, y la autorización debe incluir el nombre, la dirección, el
                número de teléfono y la dirección de correo electrónico del agente autorizado (para
                proporcionar la información personal recopilada o para responder a una solicitud de
                eliminación).
              </p>
            </li>
          </ol>
        </li>
        <li>
          <b>Cómo ejercer sus derechos</b>
          <p>
            Para ejercer los derechos de acceso, portabilidad de datos y eliminación descritos en el
            presente documento, envíenos una solicitud de consumidor verificable:
          </p>
          <ul>
            <li>Llámenos al número gratuito 866-281-3065; o</li>
            <li>escríbanos a P.O. Box 1216, Chesapeake, VA 23327-1216.</li>
          </ul>
          <p>
            Tenga en cuenta que solo puede realizar una solicitud de acceso a datos o de divulgación
            de portabilidad de datos dos veces en un período de 12 meses.
          </p>
        </li>
        <li>
          <b>Verificación de su identidad (es decir, solicitud de consumidor verificable)</b>
          <p>
            Si decide comunicarse directamente por teléfono o por escrito, deberá proporcionarnos:
          </p>
          <ul>
            <li>
              información suficiente para identificarle (p. ej., su nombre completo, dirección y
              número de referencia del cliente o del asunto);
            </li>
            <li>
              comprobante de su identidad y dirección (p. ej., una copia de su licencia de conducir
              o pasaporte, y una factura reciente de servicios públicos o tarjeta de crédito), y
            </li>
            <li>
              una descripción de su solicitud con suficientes detalles que nos permitan
              comprenderla, evaluarla y responderla correctamente.
            </li>
          </ul>
          <p>
            No estamos obligados a realizar una divulgación de acceso a datos o portabilidad de
            datos si no podemos verificar que la persona que realiza la solicitud es la persona
            sobre la que recopilamos información, o es alguien autorizado a actuar en nombre de
            dicha persona.
          </p>
          <p>
            Cualquier información personal que recopilemos de usted para verificar su identidad en
            relación con su solicitud se utilizará únicamente para fines de verificación.
          </p>
        </li>
        <li>
          <b>Tiempo y formato de respuesta</b>
          <p>
            Nos esforzamos por responder a una solicitud de consumidor verificable en un plazo de 45
            días a partir de su recepción. Si necesitamos más tiempo (hasta 90 días), le
            informaremos el motivo y período de extensión por escrito. Si tiene una cuenta con
            nosotros, le enviaremos nuestra respuesta por escrito a esa cuenta. Si no tiene una
            cuenta con nosotros, le enviaremos nuestra respuesta por escrito por correo postal o
            electrónicamente, a su elección. Cualquier divulgación que proporcionemos solo cubrirá
            el período de 12 meses anterior a la recepción de la solicitud de consumidor
            verificable. La respuesta que proporcionemos también explicará los motivos por los que
            no podemos cumplir con una solicitud, si corresponde. Para las solicitudes de
            portabilidad de datos, seleccionaremos un formato para proporcionar su información
            personal que sea fácilmente utilizable y que le permita transmitir la información de una
            entidad a otra sin obstáculos.
          </p>
          <p>
            No cobramos una tarifa por procesar o responder a su solicitud de consumidor
            verificable, a menos que sea excesiva, repetitiva o manifiestamente infundada. Si
            determinamos que la solicitud justifica una tarifa, le informaremos por qué tomamos esa
            decisión y le proporcionaremos una estimación de costos antes de completar su solicitud.
          </p>
        </li>
        <li>
          <b>Cambios en nuestro aviso de privacidad</b>
          <p>
            Nos reservamos el derecho de modificar esta política de privacidad a nuestra discreción
            y en cualquier momento. Cuando realicemos cambios en esta política de privacidad, le
            notificaremos por correo electrónico o mediante un aviso en la página de inicio de
            nuestro sitio web.
          </p>
        </li>
      </ol>
    </li>
    <li>
      <h2>CÓMO PROTEGEMOS LA INFORMACIÓN</h2>
      <p>
        Spring Oaks ha implementado medidas de seguridad físicas, electrónicas y procedimentales
        para proteger contra la divulgación o el acceso no autorizados a la información personal.
        Empleamos salvaguardas de sistemas internos y externos diseñadas para proteger la
        confidencialidad y seguridad de la información personal. La confidencialidad de cualquier
        comunicación o material transmitido hacia o desde Spring Oaks a través del sitio web o por
        correo electrónico no puede garantizarse ni está garantizada. Usted reconoce que el
        procesamiento técnico y la transmisión del contenido del sitio web pueden transferirse sin
        cifrar e implican: (a) transmisiones a través de varias redes, y (b) cambios para confirmar
        y adaptarse a los requisitos técnicos de las redes o los dispositivos de conexión. Si surge
        alguna pregunta sobre la seguridad, comuníquese con nosotros utilizando la información
        proporcionada anteriormente.
      </p>
    </li>
    <li>
      <h2>DURANTE CUÁNTO TIEMPO CONSERVAMOS SU INFORMACIÓN</h2>
      <p>
        Spring Oaks conservará sus datos personales hasta que determinemos que la información ya no
        es necesaria, o según lo exija la ley.
      </p>
    </li>
    <li>
      <h2>COOKIES</h2>
      <p>
        Una cookie es un pequeño fragmento de datos enviado desde un sitio web y almacenado en su
        computadora por su navegador web. El archivo se añade una vez que acepta almacenar cookies
        en su computadora o dispositivo, y la cookie ayuda a analizar el tráfico web o le permite
        saber cuándo visita un sitio en particular. Las cookies permiten a los sitios responderle
        como individuo. El sitio web puede adaptar sus operaciones a sus necesidades, gustos y
        disgustos recopilando y recordando información sobre sus preferencias.
      </p>
      <p>
        Este sitio web recopila cookies y puede utilizarlas por motivos que incluyen, entre otros:
        <br />
        <br />
        Analizar nuestro tráfico en Internet utilizando un paquete de análisis.
        <br />
        Identificar si ha iniciado sesión en el Sitio web.
        <br />
        Probar el contenido del Sitio web.
        <br />
        Almacenar información sobre sus preferencias.
        <br />
        Reconocer cuando vuelve al Sitio web.
        <br />
        En general, las cookies nos ayudan a proporcionarle un mejor sitio web al permitirnos
        monitorear qué páginas le parecen útiles y cuáles no. Una cookie no nos da acceso a su
        computadora ni a ninguna información sobre usted, aparte de los datos que usted decide
        compartir con nosotros.
        <br />
      </p>
      <p>
        Puede optar por aceptar o rechazar las cookies. La mayoría de los navegadores web aceptan
        las cookies automáticamente, pero, por lo general, puede modificar la configuración de su
        navegador para rechazar las cookies, si lo prefiere. Esta configuración puede impedirle
        aprovechar al máximo el sitio web.
      </p>
    </li>
    <li>
      <h2>ENLACES A OTROS SITIOS WEB</h2>
      <p>
        Nuestro sitio web puede contener enlaces para permitirle visitar otros sitios web de interés
        fácilmente. Sin embargo, una vez que haya utilizado estos enlaces para salir de nuestro
        sitio, debe tener en cuenta que no tenemos ningún control sobre ese otro sitio web. Por lo
        tanto, no podemos ser responsables de la protección y privacidad de cualquier información
        que proporcione mientras visita dichos sitios y dichos sitios no se rigen por esta
        declaración de privacidad. Debe tener cuidado y consultar la declaración de privacidad
        aplicable al sitio web en cuestión.
      </p>
    </li>

    <li>
      <h2>PROCEDIMIENTOS DE AUDITORÍA</h2>
      <p>
        Spring Oaks monitoreará periódicamente los sistemas electrónicos como el correo electrónico
        y el Internet. Cualquier infracción directa, indirecta o intento de infracción, de esta
        Política, por parte de o en nombre de un Usuario, y cualquier infracción real o intento de
        infracción por parte de un Tercero en nombre de un Usuario, se considerará una infracción de
        la Política por parte del Usuario, y el Usuario será considerado directamente responsable.
        En el caso de que Spring Oaks se entere de que alguna actividad del Usuario puede haber
        infringido esta Política y/o expuesto a Spring Oaks a responsabilidad civil o penal, Spring
        Oaks se reserva el derecho de investigar dicha actividad, monitorear, recopilar pruebas y
        bloquear el acceso a dicho material y cooperar con las autoridades legales y terceros en la
        investigación de cualquier presunta infracción de esta Política. Spring Oaks también se
        reserva el derecho de implementar mecanismos técnicos para evitar infracciones de políticas,
        incluido el monitoreo electrónico de sistemas como el correo electrónico y el Internet. Los
        usuarios que infrinjan esta Política o cualquier otra política o norma publicada por Spring
        Oaks pueden quedar sujetos a medidas disciplinarias por parte de Spring Oaks, que pueden
        incluir el despido inmediato. Además, las conductas que sean ilegales en virtud de las leyes
        aplicables pueden someter a los Usuarios al enjuiciamiento civil y, en algunos casos, penal.
      </p>
    </li>
    <li>
      <h2>FRECUENCIA DE CAPACITACIÓN Y PRUEBAS</h2>
      <p>
        Spring Oaks es el responsable del tratamiento de sus datos personales. Todo el personal de
        Spring Oaks recibirá capacitación sobre las políticas de nuestra empresa aplicables a su
        puesto tras la contratación o el ascenso. El mismo personal completará certificaciones y
        evaluaciones sobre esas políticas una vez finalizada la capacitación y anualmente.
      </p>
      <p>Las preguntas o los comentarios sobre nuestras prácticas de datos pueden enviarse a:</p>
      <p>
        Spring Oaks Capital, LLC
        <br />
        P.O. Box 1216
        <br />
        Chesapeake, VA 23327-1216
        <br />
      </p>
      <p>O bien, llamándonos al número gratuito 866-281-3065.</p>
    </li>
  </ol>
);
